<ll-titulo>Planificaciones</ll-titulo>

<div class="row">
    <div class="col-sm-6">
        <h3 class="h3_sub_title_2">Planificaciones PSU</h3>
        <menu-grid-layout [menuItems]="menuItemsCol1" [maxColumns]="1"></menu-grid-layout>
    </div>
    <div class="col-sm-6 with-separator">
        <h3 class="h3_sub_title_2">Planificaciones curriculares</h3>
        <menu-grid-layout [menuItems]="menuItemsCol2" [maxColumns]="1"></menu-grid-layout>
    </div>
</div>
