import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService } from "@puntaje/shared/core"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"

@Component({
    templateUrl: "./home-planificaciones.component.html",
    styleUrls: ["./home-planificaciones.component.scss"]
})
export class HomePlanificacionesComponent extends MenuComponent {
    menuItemsCol1: MenuItemBoxData[] = []
    menuItemsCol2: MenuItemBoxData[] = []

    constructor(
        protected store: Store<State>,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }
    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        let asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItemsCol1 = [
            {
                route: "/planificaciones/predefinidas",
                params: { tipo_instrumento: "ensayo" },
                label: "Planificaciones PSU Predefinidas",
                text: "Propuesta de planificación PSU con evaluaciones (y material).",
                linkText: "Comenzar",
                icon: "planificacion-o",
                cornerRibbon: {
                    text: "Beta"
                }
            },
            {
                route: "/planificaciones/aplicadas",
                params: {
                    tipo_instrumento: "ensayo",
                    asignatura_id:
                        this.authService.getUserData().asignatura_id || asignaturasByEvaluacionTipo["ensayo"][0].id
                },
                label: "Historial de planificaciones PSU aplicadas",
                text: "Lista de planificaciones tipo PSU que se han aplicado.",
                linkText: "Ver historial",
                icon: "editar-planificacion-o"
            }
        ]
        this.menuItemsCol2 = [
            {
                route: "/planificaciones/predefinidas",
                params: { tipo_instrumento: "curricular" },
                label: "Planificaciones curriculares Predefinidas",
                text: "Propuesta de planificación curricular con evaluaciones (y material) organizada por Objetivo de Aprendizaje (OA).",
                linkText: "Comenzar",
                icon: "planificacion-o",
                cornerRibbon: {
                    text: "Beta"
                }
            },
            {
                route: "/planificaciones/aplicadas",
                params: {
                    tipo_instrumento: "curricular",
                    asignatura_id:
                        this.authService.getUserData().asignatura_id || asignaturasByEvaluacionTipo["curricular"][0].id
                },
                label: "Historial de planificaciones curriculares aplicadas",
                text: "Lista de planificaciones curriculares que se han aplicado.",
                linkText: "Ver historial",
                icon: "editar-planificacion-o"
            }
        ]
    }
}
