<footer title="Informacion de la empresa" class="print-hide">
    <div class="txt-company-footer">
        <div class="centerColumn">
            <p>Encuéntranos también en:</p>
            <div class="socialMedia">
                <div class="t_row">
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.facebook }}" target="_blank" title="Facebook">
                            <fa name="facebook"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.twitter }}" target="_blank" title="Twitter">
                            <fa name="twitter"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                            <fa name="youtube"></fa>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightColumn">
            <div class="speechbubble">
                <fa name="phone" class="icono-telefono"></fa>
                <p>Fono contacto:</p>
                <p class="telefono">
                    <em>{{ supportPhone }}</em>
                </p>
                <p>E-mail de Monitores para ayuda a Profesores:</p>
                <p class="email">
                    <em>{{ supportMail }}</em>
                </p>
                <fa name="envelope-o" class="icono-mail"></fa>
            </div>
        </div>
        <div class="leftColumn">
            <a href="http://ogr.cl/">
                <img
                    class="footer-logo"
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/logo_ogr_light.png"
                    alt="{{ config.plataforma.info.companyName }}"
                />
            </a>
            <p>Todos los Derechos Reservados a "Open Green Road S.A." ®</p>
        </div>
    </div>
</footer>
