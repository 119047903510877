import { Component, OnInit } from "@angular/core"

import { Asignatura, Asignaturas } from "@puntaje/nebulosa/api-services"

import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AuthService } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"

/*#################################

Menú arriba del sitio.

#################################*/

@Component({
    templateUrl: "topmenu.component.html",
    selector: "topmenu",
    styleUrls: ["topmenu.component.scss"]
})
export class TopmenuComponent extends MenuComponent {
    constructor(store: Store<State>, public authService: AuthService) {
        super(store)
    }
}
