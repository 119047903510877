<div (click)="hideSuccess()">
    <div class="contactSuccess" *ngIf="showSuccess">
        <div class="contactSuccess-inner">
            <div class="contactSuccess-left-icon">
                <fa name="smile-o"></fa>
            </div>
            <div class="contactSuccess-text">
                Mensaje enviado con éxito. ¡Gracias por ponerte en contacto con nosotros!
            </div>
        </div>
    </div>
    <div class="contactSending" *ngIf="submitted">
        <div class="contactSending-inner">
            <div class="contactSuccess-text">Enviando tu mensaje...</div>
        </div>
    </div>
    <form [formGroup]="form" novalidate (ngSubmit)="save(form.value, form.valid)">
        <div class="row">
            <div class="col-md-6">
                <div class="input-container">
                    <label>
                        Nombre
                        <span class="required-mark"></span>
                    </label>
                    <input
                        type="text"
                        formControlName="nombre"
                        class="form-control"
                        placeholder="Escriba su primer nombre"
                    />
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.nombre.valid && (form.controls.nombre.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Nombre es requerido (mínimo 2 caracteres).
                    </div>
                </div>
                <div class="input-container">
                    <label>
                        Apellido paterno
                        <span class="required-mark"></span>
                    </label>
                    <input
                        type="text"
                        formControlName="apellido_paterno"
                        class="form-control"
                        placeholder="Ej: González"
                    />
                    <div
                        class="warning-error"
                        *ngIf="
                            !form.controls.apellido_paterno.valid &&
                            (form.controls.apellido_paterno.touched || submitted)
                        "
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Apellido paterno es requerido (mínimo 2 caracteres).
                    </div>
                </div>
                <div class="input-container">
                    <label>
                        Apellido materno
                        <span class="required-mark"></span>
                    </label>
                    <input
                        type="text"
                        formControlName="apellido_materno"
                        class="form-control"
                        placeholder="Ej: Muñoz"
                    />
                    <div
                        class="warning-error"
                        *ngIf="
                            !form.controls.apellido_materno.valid &&
                            (form.controls.apellido_materno.touched || submitted)
                        "
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Apellido materno es requerido (mínimo 2 caracteres).
                    </div>
                </div>
                <div class="input-container">
                    <label>
                        Email
                        <span class="required-mark"></span>
                    </label>
                    <input
                        type="email"
                        formControlName="email"
                        class="form-control"
                        placeholder="Ej: correo@mail.com"
                    />
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.email.valid && (form.controls.email.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Email es requerido (y debe ser válido).
                    </div>
                </div>
                <div class="input-container">
                    <label>
                        Teléfono
                        <span class="required-mark"></span>
                    </label>
                    <input type="text" formControlName="telefono" class="form-control" placeholder="Teléfono" />
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.telefono.valid && (form.controls.telefono.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Teléfono es requerido (y debe ser válido).
                    </div>
                </div>
                <div class="input-container">
                    <label>Establecimiento</label>
                    <input
                        type="text"
                        formControlName="establecimiento"
                        class="form-control"
                        placeholder="Ej: nombre de tu colegio"
                    />
                    <div
                        class="warning-error"
                        *ngIf="
                            !form.controls.establecimiento.valid && (form.controls.establecimiento.touched || submitted)
                        "
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Establecimiento debe tener mínimo 2 caracteres.
                    </div>
                </div>
                <div class="input-container">
                    <label>
                        Ciudad
                        <span class="required-mark"></span>
                    </label>
                    <input
                        type="text"
                        formControlName="lugar"
                        class="form-control"
                        placeholder="Ciudad o municipalidad"
                    />
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.lugar.valid && (form.controls.lugar.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Ciudad o municipalidad es requerido.
                    </div>
                </div>
                <div class="input-container" *ngIf="enableRut">
                    <label>
                        RUT
                        <span class="required-mark"></span>
                    </label>
                    <input type="text" formControlName="rut" class="form-control" placeholder="Ej: 1234567-8" />
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.rut.valid && (form.controls.rut.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        El Rut es inválido. Rut debe ser ingresado con guión y sin puntos.
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <!-- <div class="input-container">
				    <label>Me contacto para hablarle sobre</label>
					<checkboxes [options]="plataformas" formControlName="plataforma">
					</checkboxes>
				</div> -->
                <div class="input-container">
                    <label>
                        Motivo del contacto
                        <span class="required-mark"></span>
                    </label>
                    <!-- motivo general de contacto -->
                    <div class="radio">
                        <label [attr.for]="'radio_0'">
                            <input
                                type="radio"
                                id="radio_0"
                                [value]="0"
                                formControlName="motivo_general"
                                [(ngModel)]="radio_motivo"
                            />
                            {{ tipos_contacto[0] }}
                        </label>
                    </div>
                    <div class="radio">
                        <label [attr.for]="'radio_1'">
                            <input
                                type="radio"
                                id="radio_1"
                                [value]="1"
                                formControlName="motivo_general"
                                [(ngModel)]="radio_motivo"
                            />
                            {{ tipos_contacto[1] }}
                        </label>
                    </div>
                    <div class="radio">
                        <label [attr.for]="'radio_2'">
                            <input
                                type="radio"
                                id="radio_2"
                                [value]="2"
                                formControlName="motivo_general"
                                [(ngModel)]="radio_motivo"
                            />
                            {{ tipos_contacto[2] }}
                        </label>
                    </div>
                </div>
                <div class="input-container">
                    <!-- #################### -->
                    <textarea
                        formControlName="motivo"
                        class="form-control"
                        placeholder="Motivo del contacto"
                        rows="10"
                    ></textarea>
                    <div
                        class="warning-error"
                        *ngIf="!form.controls.motivo.valid && (form.controls.motivo.touched || submitted)"
                    >
                        <span class="glyphicon glyphicon-remove"></span>
                        Motivo del contacto es requerido.
                    </div>
                </div>
                <cui-button type="submit" [isBlock]="true" class="btn_style">Contactar</cui-button>
            </div>
        </div>
    </form>
</div>
