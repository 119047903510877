<ll-titulo>Bienvenid@</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
        <noticias-showcasebox></noticias-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <grupo-usuarios-profesor-showcase></grupo-usuarios-profesor-showcase>
        <br />
        <lista-establecimientos-profesor></lista-establecimientos-profesor>
        <br />
        <dudas-showcase></dudas-showcase>
    </div>
    <div class="col-md-4 col-sm-6">
        <div class="destacados-content">
            <div class="destacados clearfix">
                <div class="icon-circle"><fa name="star-o"></fa></div>
                Destacados
            </div>
            <menu-grid-layout [menuItems]="menuItems" [maxColumns]="1" class="menuDestacados"></menu-grid-layout>
        </div>
        <br />
    </div>
</div>
<typeform-widgets-view></typeform-widgets-view>