import { Component, HostBinding, OnDestroy, Output, EventEmitter } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService, TabService, SessionService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { DomSanitizer } from "@angular/platform-browser"
import { Subscription } from "rxjs"

/*#################################

Barra de navegación que va en la parte superior.

#################################*/

@Component({
    templateUrl: "navbar.component.html",
    selector: "navbar",
    styleUrls: ["navbar.component.scss"]
})
export class NavbarComponent implements OnDestroy {
    @HostBinding("class.profile1") useProfile1: boolean = false
    usuario_nombre: string
    usuario_apellido_paterno: string
    usuario_id: string
    usuario_avatar: string
    companyName: string
    userUpdateSubscription: Subscription
    @Output() toggleSidemenuEvent = new EventEmitter()

    config: typeof config

    constructor(
        public authService: AuthService,
        protected router: Router,
        protected sanitizer: DomSanitizer,
        protected tabService: TabService,
        protected sessionService: SessionService
    ) {
        this.useProfile1 = this.authService.userProfile == "profile1"
        let usuario = this.authService.getUserData()
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null

        this.userUpdateSubscription = this.sessionService.userDataUpdated.subscribe(usuario => {
            this.updateUserData(usuario)
        })
        this.companyName = config.plataforma.info.companyName

        this.config = config
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    logout() {
        this.authService.logout()
        // Redirect the user
        this.router.navigate(["/landing"])
    }

    edit() {
        if (this.usuario_id != null) {
            this.router.navigate(["/usuarios/editar"])
        }
    }

    goToAyuda() {
        this.router.navigate(["/ayudas"])
    }

    goToProfile() {
        if (this.usuario_id != null) {
            this.tabService.setComponentTab("UsuarioComponent", "perfil")
            this.router.navigate(["/usuarios/" + this.usuario_id])
        }
    }

    goToAchievements() {
        if (this.usuario_id != null) {
            this.tabService.setComponentTab("UsuarioComponent", "logros")
            this.router.navigate(["/usuarios/" + this.usuario_id])
        }
    }

    sanitize(input: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(input)
    }

    updateUserData(usuario) {
        //usuario = this.authService.getUserData();
        this.usuario_id = usuario.id ? usuario.id + "" : "1"
        this.usuario_nombre = usuario.nombre ? usuario.nombre + "" : null
        this.usuario_apellido_paterno = usuario.apellido_paterno ? usuario.apellido_paterno + "" : null
        this.usuario_avatar = usuario.avatar ? usuario.avatar + "" : null
    }

    ngOnDestroy() {
        this.userUpdateSubscription.unsubscribe()
    }

    toggleSidemenu() {
        this.toggleSidemenuEvent.emit(null)
    }
}
