import { Component, OnInit, Input } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv

import { ContactForm } from "./contact_form.interface"
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from "@angular/forms"
import { Validations } from "@puntaje/shared/core"

class PlataformaContacto {
    constructor(protected value: string) {}
    toString() {
        return this.value
    }
}

@Component({
    selector: "contact-form",
    templateUrl: "contact_form.component.html",
    styleUrls: ["contact_form.component.scss"]
})
export class ContactFormComponent implements OnInit {
    form: UntypedFormGroup
    contactForm: ContactForm
    submitted: boolean
    showSuccess: boolean
    pais: string
    tipos_contacto: string[] = []
    radio_motivo: number = 0
    @Input() indicePlataforma: number = 0
    @Input() enableRut: boolean = false

    constructor(protected formBuilder: UntypedFormBuilder, protected http: HttpClient) {
        this.pais = config.plataforma.pais
    }

    ngOnInit() {
        this.buildForm()
    }

    hideSuccess() {
        if (this.showSuccess) {
            this.showSuccess = false
        }
    }

    buildForm() {
        this.form = this.formBuilder.group({
            nombre: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(200)]],
            apellido_paterno: [
                "",
                [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(200)]
            ],
            apellido_materno: [
                "",
                [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(200)]
            ],
            email: ["", [<any>Validators.required, <any>Validators.email]],
            telefono: ["", [<any>Validators.required, <any>Validators.minLength(5), <any>Validators.maxLength(15)]],
            establecimiento: ["", [<any>Validators.minLength(2), <any>Validators.maxLength(250)]],
            lugar: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(300)]],
            rut: ["", [<any>Validators.required, Validations.validateRutOrEmpty]],
            motivo: ["", [<any>Validators.required, <any>Validators.minLength(2), <any>Validators.maxLength(2000)]],
            motivo_general: ["", [<any>Validators.required]]
        })
        this.tipos_contacto = ["Tengo un problema con mi cuenta.", "Contacto área comercial.", "Otro."]
        this.radio_motivo = 0
        this.form.controls.motivo_general.setValue(0)
    }

    save(model: ContactForm, isValid: boolean) {
        this.showSuccess = false
        this.submitted = true
        let mail_data = { contacto: model }
        if (isValid) {
            let url = environment.endpoints.base + "/contacto"
            this.http
                .post(url, mail_data)
                .toPromise()
                .then(response => {
                    this.submitted = false
                    this.buildForm()
                    this.showSuccess = true
                })
        }
    }
}
