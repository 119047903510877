import { PuntajeEnsayosModule } from "@puntaje/puntaje/core"
import { CUILayoutsModule } from "@puntaje/shared/cui"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"
import { RouterModule } from "@angular/router"
import { TabsModule } from "ngx-bootstrap"

import { ApiModule } from "@puntaje/puntaje/api-services"
import { routing } from "./profesores_ensayos.routing"

import { EnsayosModule } from "@puntaje/puntaje/new-modules/ensayos"
import { SharedModule } from "@puntaje/puntaje/new-modules/shared"
import { InstrumentosModule } from "@puntaje/puntaje/new-modules/instrumentos"

import { ProfesoresLayoutsModule } from "../layouts/profesores_layouts.module"
import { Angular2FontawesomeModule } from "@ogr-sa/angular2-fontawesome"

import { HomeEnsayosComponent } from "./home_ensayos.component"
import { HomePruebasComponent } from "./home_pruebas.component"

//import { PreguntaEnsayoComponent } from "./pregunta_ensayo.component";

import { UtilModule, FormModule, TranslationsModule } from "@puntaje/shared/core"
import { NebuModule } from "@puntaje/nebulosa/api-services"

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        EnsayosModule,
        InstrumentosModule,
        SharedModule,
        ProfesoresLayoutsModule,
        UtilModule,
        routing,
        Angular2FontawesomeModule,
        FormModule,
        NebuModule,
        TranslationsModule,
        TabsModule,
        CUILayoutsModule,
        PuntajeEnsayosModule
    ],
    declarations: [HomeEnsayosComponent, HomePruebasComponent],
    exports: []
})
export class ProfesoresEnsayosModule {}
