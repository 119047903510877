import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import {
    EnsayoInstanciasComponent,
    EvaluacionComponent,
    EvaluacionesCorregirComponent,
    GenerarEnsayoProfesorComponent,
    RealizarEnsayoComponent,
    ResultadosEnsayoAlumnoComponent,
    ResultadosEnsayoComponent,
    RevisarEnsayosComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeEnsayosComponent } from "./home_ensayos.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { EstadisticasComponent, ProgresoComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/generar_ensayo2/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },

    {
        path: "evaluaciones",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/generar_ensayo2/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/subir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayo_instancias_estudiante/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },

    {
        path: "ensayos/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
