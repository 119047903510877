<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/dudas_categorias']" role="button">
                    <ogr-icon name="dialogos" align="top"></ogr-icon>
                    &nbsp;Comunidad
                </a>
                <!--<a [routerLink]="['/dudas']" role="button"><ogr-icon name='dialogos' align="top"></ogr-icon>&nbsp;Comunidad</a>-->
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/ensayos']" role="button">
                    <ogr-icon name="prueba" align="top"></ogr-icon>
                    &nbsp;Ensayos PSU
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/pruebas']" role="button">
                    <ogr-icon name="guia" align="top"></ogr-icon>
                    &nbsp;Pruebas curriculares
                </a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/bibliotecas']" role="button">
                    <ogr-icon name="libro2-o" align="top"></ogr-icon>
                    &nbsp;Biblioteca
                </a>
                <a [routerLink]="['/biblioteca']" style="display: none; pointer-events: none; visibility: hidden"></a>
                <a [routerLink]="['/materiales']" style="display: none; pointer-events: none; visibility: hidden"></a>
            </li>
            <!-- <li routerLinkActive="active-menu">
                <a [routerLink]="['/planes_personales']" role="button"><ogr-icon name='editar-prueba' align="top"></ogr-icon>&nbsp;Planes personales</a>
                <a [routerLink]="['/planes_estudio']" style="display: none;pointer-events: none;visibility: hidden;"></a>
                <a [routerLink]="['/plan_estudios']" style="display: none;pointer-events: none;visibility: hidden;"></a>
                <a [routerLink]="['/planes_personales']" style="display: none;pointer-events: none;visibility: hidden;"></a>
                <a [routerLink]="['/plan_personal']" style="display: none;pointer-events: none;visibility: hidden;"></a>
                <a [routerLink]="['/diagnostico']" style="display: none;pointer-events: none;visibility: hidden;"></a>
            </li> -->
            <li routerLinkActive="active-menu">
                <div corner-ribbon>Nuevo</div>
                <a [routerLink]="['/planificaciones']" role="button">
                    <ogr-icon name="planificacion-o" align="top"></ogr-icon>
                    &nbsp;Planificaciones
                </a>
            </li>
            <li *ngIf="authService.getEstablecimientos().length == 0" routerLinkActive="active-menu">
                <a [routerLink]="['/grupo_usuarios']" role="button">
                    <ogr-icon name="comunidad-o" align="top"></ogr-icon>
                    &nbsp;Mis cursos
                </a>
            </li>
            <li *ngIf="authService.getEstablecimientos().length != 0" routerLinkActive="active-menu">
                <a [routerLink]="['/establecimientos']" role="button">
                    <ogr-icon name="establecimiento-o" align="top"></ogr-icon>
                    &nbsp;Administrar
                </a>
                <a
                    [routerLink]="['/estadisticas/resumen_uso']"
                    style="display: none; pointer-events: none; visibility: hidden"
                ></a>
                <a
                    [routerLink]="['/grupo_usuarios']"
                    style="display: none; pointer-events: none; visibility: hidden"
                ></a>
            </li>
        </ul>
    </div>
</nav>
